import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },
  },
  actions: {
    loadUsers ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/reports/users', payload)
        .then(response => response.data)
        .then(data => {
          resolve(data.data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
}