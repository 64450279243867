import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layout/DashboardLayout.vue'),
    redirect: '/dashboard',
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const preloads = [
        store.dispatch('settings/load', {}),
        store.dispatch('auth/loadFilters', {}),
      ]
      const user = store.getters['auth/user']
      if (user.is_admin) {
        preloads.push(
          store.dispatch('users/load', {}),
        )
      }
      Promise.all(preloads)
      next()
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/documentation',
        name: 'Documentation',
        component: () => import('../views/Documentation.vue')
      },
      {
        path: '/manage',
        name: 'Manage',
        meta: {
          requiresAdmin: true
        },
        component: () => import('../views/Manage.vue')
      },
      {
        path: '/reports',
        name: 'Reports',
        meta: {
          requiresAdmin: true
        },
        component: () => import('../views/Reports.vue')
      },
      {
        path: '/company',
        name: 'Company Details',
        component: () => import('../views/Company.vue')
      },
      {
        path: '/profile',
        name: 'Edit Profile',
        component: () => import('../views/Profile.vue')
      },
    ],
  },
  {
    path: '/',
    name: 'auth',
    component: () => import('../layout/AuthLayout.vue'),
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/Login.vue'),
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
  },
  {
    path: "*",
    component: () => import('../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['auth/isLoggedIn']
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  if (!isLoggedIn && requiresAuth) {
    next('/login')
    return
  }

  if (isLoggedIn && (to.name === 'login')) {
    next('/')
    return
  }

  let authUser = store.getters['auth/user']
  if (requiresAdmin && (!authUser || !authUser.is_admin)) {
    next('/')
    return
  }

  next()
})

export default router
