import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    error: null,
    loading: false,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
  actions: {
  },
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    appVersion (state) {
      return state.appVersion
    },
  },
  modules,
})
