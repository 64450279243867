import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
    size: 10,
    page: 1,

    total: 0,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
    size (state) {
      return state.size || 10
    },
    page (state) {
      return state.page || 1
    },
    total (state) {
      return state.total || 0
    },
  },
  actions: {
    load ({commit/*, getters*/, rootGetters}, options = {}) {
      const payload = {
        dateFrom: rootGetters['filters/dateFrom'],
        dateTo: rootGetters['filters/dateTo'],
        prospecting: rootGetters['filters/prospecting'],
        selectedSiteIDs: rootGetters['filters/selectedSiteIDs'],
        size: rootGetters['companies/size'],
        page: rootGetters['companies/page'],
        ...options,
      }
      commit('setLoading', true)
      return Vue.http.post('zoominfo/get_companies_list', payload)
      .then(response => response.data)
      .then(data => {
        commit('setData', data.data.companies)
        commit('setTotal', data.data.total)
        return data.data.companies
      })
      .catch(e => {
        commit('setError', e)
        commit('setData', [])
        commit('setTotal', 0)
        return []
      })
      .finally(() => commit('setLoading', false))
    },
    loadCompanyData ({commit}, payload) {
      return Vue.http.post('zoominfo/get_company_data', payload)
      .then(response => response.data)
      .then(data => {
        return data.data
      })
      .catch(e => {
        commit('setError', e)
        return null
      })
    },
    loadCompanyDetails ({commit, rootGetters}, options) {
      const payload = {
        dateFrom: rootGetters['filters/dateFrom'],
        dateTo: rootGetters['filters/dateTo'],
        selectedSiteIDs: rootGetters['filters/selectedSiteIDs'],
        ...options,
      }
      return Vue.http.post('zoominfo/get_company_details', payload)
      .then(response => response.data)
      .then(data => {
        return data.data
      })
      .catch(e => {
        commit('setError', e)
        return null
      })
    },
    loadCompanyContacts ({commit}, payload) {
      return Vue.http.post('zoominfo/get_company_contacts', payload)
      .then(response => response.data)
      .then(data => {
        return data.data
      })
      .catch(e => {
        commit('setError', e)
        return null
      })
    },
    loadContactDetails ({commit}, payload) {
      return Vue.http.post('zoominfo/get_contact_details', payload)
      .then(response => response.data)
      .then(data => {
        return data.data
      })
      .catch(e => {
        commit('setError', e)
        return null
      })
    },
    setAlertStatus ({commit}, payload) {
      return Vue.http.post('zoominfo/set_company_alert_status', payload)
      .then(response => response.data)
      .catch(e => {
        commit('setError', e)
        return null
      })
    },
    sendContactToFastLeads ({commit}, payload) {
      return Vue.http.post('zoominfo/send_contact_to_fastleads', payload)
      .then(response => response.data)
      .then(data => {
        return data.data
      })
      .catch(e => {
        commit('setError', e)
        return null
      })
    }
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    setTotal (state, payload) {
      state.total = payload
    },

    setSize (state, payload) {
      state.size = payload
    },
    setPage (state, payload) {
      state.page = payload
    },

    resetPage (state) {
      state.page = 1
    },
  },
}