import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
  },
  actions: {
    load ({commit, getters}, payload) {
      commit('setLoading', true)
      return Vue.http.post('zoominfo/get_sites', payload)
      .then(response => response.data)
      .then(data => {
        commit('setData', data.data.filter(site => site.is_active))
        return getters.data
      })
      .catch(e => commit('setError', e))
      .finally(() => commit('setLoading', false))
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
      // state.selected = []
    },
  },
}