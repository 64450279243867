import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],

    filter_loading: false,
    user_filters: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },

    filterLoading: state => state.filter_loading,
    userFilters: state => state.user_filters || [],
  },
  actions: {
    load ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/get_targets', payload)
        .then(response => response.data)
        .then(data => {
          commit('setData', data.data)
          resolve(getters.data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/submit_target', payload)
        .then(response => response.data)
        .then(data => {
          commit('updateData', data.data)
          resolve(data.data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/delete_target', payload)
        .then(response => response.data)
        .then(data => {
          commit('deleteData', data.data)
          resolve(data.data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    submitFilter ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/submit_user_filter', payload)
        .then(response => response.data)
        .then(data => {
          commit('updateFilters', data.data)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    deleteFilter ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/delete_user_filter', payload)
        .then(response => response.data)
        .then(data => {
          commit('deleteFilter', data.data)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    deleteFilters ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/delete_user_filters', payload)
        .then(response => response.data)
        .then(data => {
          commit('deleteFilters', payload)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    loadFilters ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/get_user_filters', payload)
        .then(response => response.data)
        .then(data => {
          commit('setFilters', data.data)
          resolve(getters.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const userIndex = state.data.findIndex(user => user.id == target.id)
      if (userIndex > -1) {
        state.data.splice(userIndex, 1, target)
      } else {
        state.data.push(target)
      }
    },
    deleteData (state, target) {
      const userIndex = state.data.findIndex(user => user.id == target.id)
      if (userIndex > -1) {
        state.data.splice(userIndex, 1)
      }
    },

    setFilterLoading (state, payload) {
      state.filter_loading = payload
    },
    setFilters (state, payload) {
      state.user_filters = (payload || []).map(filter => ({
        ...filter,
        meta: JSON.parse(filter.meta || '{}'),
        data: JSON.parse(filter.data || '[]'),
      }))
    },
    updateFilters (state, target) {
      const filterIndex = state.user_filters.findIndex(filter => filter.id == target.id)
      if (filterIndex > -1) {
        state.user_filters.splice(filterIndex, 1, target)
      } else {
        state.user_filters.push(target)
      }
    },
    deleteFilter (state, target) {
      const filterIndex = state.user_filters.findIndex(filter => filter.id == target.id)
      if (filterIndex > -1) {
        state.user_filters.splice(filterIndex, 1)
      }
    },
    deleteFilters (state, targets) {
      targets.forEach(target_id => {
        const filterIndex = state.user_filters.findIndex(filter => filter.id == target_id)
        if (filterIndex > -1) {
          state.user_filters.splice(filterIndex, 1)
        }
      })
    },
  },
}