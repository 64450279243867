import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
  },
  actions: {
    load ({commit, getters}, payload) {
      commit('setLoading', true)
      return Vue.http.post('zoominfo/get_jobtitles', payload)
      .then(response => response.data)
      .then(data => {
        commit('setData', data.data)
        return getters.data
      })
      .catch(e => commit('setError', e))
      .finally(() => commit('setLoading', false))
    },
    save ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/update_jobtitles', payload)
        .then(response => response.data)
        .then(data => {
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
      // state.selected = []
    },
  },
}