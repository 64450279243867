import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,
    
    industry_codes: [],
    industry_presets: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    industry_codes (state) {
      return state.industry_codes || []
    },
    industry_presets (state) {
      return state.industry_presets || []
    },
    industry_presets_primary (state) {
      return state.industry_presets.find(preset => preset.Id == 'primary')
    },
  },
  actions: {
    load ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/get_industry_settings', payload)
        .then(response => response.data)
        .then(data => {
          commit('setData', data.data)
          resolve()
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    addPreset ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/add_industry_preset', payload)
        .then(response => response.data)
        .then(data => {
          commit('addPreset', payload)
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    updatePreset ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/update_industry_preset', payload)
        .then(response => response.data)
        .then(data => {
          commit('updatePreset', payload)
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
    removePreset ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('zoominfo/remove_industry_preset', payload)
        .then(response => response.data)
        .then(data => {
          commit('removePreset', payload)
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.industry_codes = payload.industry_codes
      state.industry_presets = payload.industry_presets
    },

    addPreset (state, payload) {
      state.industry_presets.push(payload)
    },
    updatePreset (state, payload) {
      const existingIndex = state.industry_presets.findIndex(preset => preset.id == payload.id)
      if (existingIndex > -1) {
        state.industry_presets.splice(existingIndex, 1, payload)
      }
    },
    removePreset (state, payload) {
      const existingIndex = state.industry_presets.findIndex(preset => preset.id == payload.id)
      if (existingIndex > -1) {
        state.industry_presets.splice(existingIndex, 1)
      }
    },

    updateData (state, target) {
      const userIndex = state.data.findIndex(user => user.id == target.id)
      if (userIndex > -1) {
        state.data.splice(userIndex, 1, target)
      } else {
        state.data.push(target)
      }
    },
    deleteData (state, target) {
      const userIndex = state.data.findIndex(user => user.id == target.id)
      if (userIndex > -1) {
        state.data.splice(userIndex, 1)
      }
    },
  },
}