import Vue from 'vue'
import router from "@/router/index"

export default {
  namespaced: true,
  state: () => ({
    auth_status: '',
    token: localStorage.getItem('token') || '',
    user : null,
    filter_loading: false,
    user_filters: [],
  }),
  getters: {
    isLoggedIn: state => !!state.token,
    status: state => state.auth_status,
    loading: state => state.auth_status == 'loading',
    error: state => state.auth_status == 'error',
    success: state => state.auth_status == 'success',
    user: state => state.user || {},
    filterLoading: state => state.filter_loading,
    userFilters: state => state.user_filters || [],
  },
  mutations: {
    auth_request(state) {
      state.auth_status = 'loading'
    },
    auth_success(state, {token, user}) {
      state.auth_status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.auth_status = 'error'
    },
    logout(state) {
      state.auth_status = ''
      state.token = ''
      state.user = null
    },
    user_fill(state, {user}) {
      state.auth_status = 'success'
      state.user = user
    },
    setFilterLoading (state, payload) {
      state.filter_loading = payload
    },
    setFilters (state, payload) {
      state.user_filters = (payload || []).map(filter => ({
        ...filter,
        meta: JSON.parse(filter.meta || '{}'),
        data: JSON.parse(filter.data || '[]'),
      }))
    },
    updateFilters (state, target) {
      const filterIndex = state.user_filters.findIndex(filter => filter.id == target.id)
      if (filterIndex > -1) {
        state.user_filters.splice(filterIndex, 1, target)
      } else {
        state.user_filters.push(target)
      }
    },
    deleteFilter (state, target) {
      const filterIndex = state.user_filters.findIndex(filter => filter.id == target.id)
      if (filterIndex > -1) {
        state.user_filters.splice(filterIndex, 1)
      }
    },
    deleteFilters (state, targets) {
      targets.forEach(target_id => {
        const filterIndex = state.user_filters.findIndex(filter => filter.id == target_id)
        if (filterIndex > -1) {
          state.user_filters.splice(filterIndex, 1)
        }
      })
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('zoominfo/auth/login', payload)
        .then(resp => {
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw new Error(message || error || 'Unknown Error')
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          commit('filters/setSelectedSiteIDs', JSON.parse(user.sites), { root: true })
          commit('filters/setUserSiteIDs', JSON.parse(user.sites), { root: true })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    loginRequest({commit}, payload) {
      return new Promise((resolve, reject) => {
        // commit('auth_request')
        Vue.http.post('zoominfo/auth/login-request', payload)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    loginWithCode({commit}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('zoominfo/auth/login-with-code', payload)
        .then(resp => {
          commit('auth_request')
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw new Error(message || error || 'Unknown Error')
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          commit('filters/setSelectedSiteIDs', JSON.parse(user.sites), { root: true })
          commit('filters/setUserSiteIDs', JSON.parse(user.sites), { root: true })
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete Vue.http.headers.common['Authorization']
        router.push('/login', function() {
          resolve()
        })
      })
    },
    getUser({commit}) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('zoominfo/auth/get-user', {})
        .then(resp => {
          const {
            user,
          } = resp.data
          commit('user_fill', {user})
          commit('filters/setSelectedSiteIDs', JSON.parse(user.sites), { root: true })
          commit('filters/setUserSiteIDs', JSON.parse(user.sites), { root: true })
          resolve()
        }).catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
    update({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('zoominfo/auth/update-user', payload)
        .then(resp => {
          const {
            user,
          } = resp.data
          commit('user_fill', {user})
          commit('filters/setSelectedSiteIDs', JSON.parse(user.sites), { root: true })
          commit('filters/setUserSiteIDs', JSON.parse(user.sites), { root: true })
          resolve()
        }).catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
    submitFilter ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/submit_user_filter', payload)
        .then(response => response.data)
        .then(data => {
          commit('updateFilters', data.data)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    deleteFilter ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/delete_user_filter', payload)
        .then(response => response.data)
        .then(data => {
          commit('deleteFilter', data.data)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    deleteFilters ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/delete_user_filters', payload)
        .then(response => response.data)
        .then(data => {
          commit('deleteFilters', payload)
          resolve(data.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
    loadFilters ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setFilterLoading', true)
        Vue.http.post('zoominfo/get_user_filters', payload)
        .then(response => response.data)
        .then(data => {
          commit('setFilters', data.data)
          resolve(getters.data)
        })
        .catch(e => {
          reject(e)
        })
        .finally(() => commit('setFilterLoading', false))
      })
    },
  }

}