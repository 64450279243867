import auth from './auth'
import filters from './filters'
import companies from './companies'
import users from './users'
import settings from './settings'
import reports from './reports'

export default {
  auth,
  filters,
  companies,
  users,
  settings,
  reports,
}
