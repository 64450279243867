export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    prospecting: false,
    selectedSiteIDs: [],
    userSiteIDs: [],

    dateFrom: new Date(),
    dateTo: new Date(),
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    prospecting (state) {
      return state.prospecting
    },
    selectedSiteIDs (state) {
      return state.selectedSiteIDs || []
    },
    userSites (state, getters, rootState, rootGetters) {
      return rootGetters['settings/sites/data'].filter(site => state.userSiteIDs.includes(site.id))
    },

    dateFrom (state) {
      const dateFrom = new Date(state.dateFrom)
      dateFrom.setHours(0, 0, 0)
      return dateFrom
    },
    dateTo (state) {
      const dateTo = new Date(state.dateTo)
      dateTo.setHours(24, 0, 0)
      return dateTo
    },
  },
  actions: {
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setProspecting(state, prospecting) {
      state.prospecting = prospecting
    },
    setSelectedSiteIDs(state, site_ids) {
      state.selectedSiteIDs = site_ids
    },
    toggleSelected (state, site_id) {
      if (!state.selectedSiteIDs.includes(site_id)) {
        state.selectedSiteIDs.push(site_id)
      } else {
        state.selectedSiteIDs.splice(state.selectedSiteIDs.findIndex(t => t == site_id), 1)
      }
    },
    setUserSiteIDs (state, site_ids) {
      state.userSiteIDs = site_ids
    },

    setDateFrom (state, dateFrom) {
      state.dateFrom = dateFrom
    },
    setDateTo (state, dateTo) {
      state.dateTo = dateTo
    },
  },
}