import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBpzxo-a-S9teu39xX3upeau5GDzneMk14',
  }
});

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/styles/main.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import VueResource from 'vue-resource'
Vue.use(VueResource)

import Treeselect from '@riophae/vue-treeselect'
Vue.component('treeselect', Treeselect)

import moment from 'moment-timezone'
Vue.prototype.moment = moment

Vue.filter('moment', function (value, format = '') {
  return moment(value).format(format)
})

const API_BASE_URL = process.env.NODE_ENV == 'production' ? 'https://iq.sixaxisllc.com' : 'http://127.0.0.1:9001' // 'https://api-sixaxis.ngrok.io'
Vue.http.options.root = API_BASE_URL
Vue.http.interceptors.push((request, next) => {
  next((response) => {
    if (response.status == 401) {
      store.dispatch('auth/logout')
    }
  })
})
const token = localStorage.getItem('token')
if (token) {
  Vue.http.headers.common['Authorization'] = 'Bearer ' + token
}

(() => token ? store.dispatch('auth/getUser') : Promise.resolve())().finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

// custom prototypes
Date.getDateString = (t = new Date()) => {
  var year = t.getFullYear()
  var month = '0' + (t.getMonth() + 1)
  var day = '0' + t.getDate()
  return `${year}-${month.substr(-2)}-${day.substr(-2)}`
}

Array.equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false
  const uniqueValues = new Set([...a, ...b])
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length
    const bCount = b.filter(e => e === v).length
    if (aCount !== bCount) return false
  }
  return true
}
